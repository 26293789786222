@import '../../../styles/utilities/mixins/media-queries.scss';

.ReactModal__Overlay {
  height: 100vh;
}

.create-stream-modal {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow-y: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  border-radius: 4px;
  outline: none;

  @media (min-width: 992px) {
    top: 160px;
    right: 160px;
    bottom: 160px;
    left: 160px;
  }
}
