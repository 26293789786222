.emoji-colors {
  img {
    max-width: 30px;
  }

  .emoji-color {
    height: 30px;
    width: 30px;
  }

  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr repeat(7, [col-start] 1fr [col-end]);
  grid-template-rows: repeat(calc(3 + var(--country-numbers)), 1fr);
  justify-items: center;
}
