.floatingEmojiContainer {
  bottom: 20px;
  height: 59px;
  width: 320px;
  left: 0;
  right: 0;
  pointer-events: none;
  margin: auto;

  @media (min-width: 992px) {
    left: auto;
    right: 20px;
    width: 360px;
    margin: 0;
  }

  &--newAdded {
    bottom: -5px;
  }

  &--others {
    bottom: -5px;
  }

  .emojiButton {
    position: absolute;
    bottom: 0;
  }

  .bubble {
    height: 30px;
    width: 30px;
    position: absolute;
    border-radius: 50%;
    bottom: 0;

    // &.x1 {
    //     animation: moveclouds 15s linear 1,
    //         sideWays 4s ease-in-out infinite alternate;
    //     & ~ .x1 {
    //         animation-delay: 300ms;
    //     }
    // }

    // &.x2 {
    //     animation: moveclouds 25s linear 1,
    //         sideWays 5s ease-in-out infinite alternate;
    //     & ~ .x2 {
    //         animation-delay: 300ms;
    //     }
    // }

    // &.x3 {
    //     animation: moveclouds 20s linear 1,
    //         sideWays 4s ease-in-out infinite alternate;
    //     & ~ .x3 {
    //         animation-delay: 300ms;
    //     }
    // }

    // &.x4 {
    //     animation: moveclouds 18s linear 1,
    //         sideWays 4s ease-in-out infinite alternate;
    //     & ~ .x4 {
    //         animation-delay: 300ms;
    //     }
    // }

    // &.x5 {
    //     animation: moveclouds 22s linear 1,
    //         sideWays 4s ease-in-out infinite alternate;
    //     & + .x5 {
    //         animation-delay: 300ms;
    //     }
    // }
  }
}

// Total numner of snowflakes
// $snowflakes: 250;

// // Randomize the animation and positioning for each snowflake
// @for $i from 1 through $snowflakes {
//     // Position of the snowflake on the y-axis
//     $top: (random(50) + 50) * 1%;

//     // Position of the snowflake on the x-axis
//     $left: random(100) * 1%;

//     // Animation delay for the flake
//     $delay: random(20) - 1s;

//     // Floating span for the flake
//     $duration: random(6) + 4s;

//     // Size of the flake
//     $size: random(24);

//     /* Snowflake ##{$i} */
//     .bubble:nth-of-type(#{$i}) {
//         animation-name: snowflake-#{$i};
//         animation-delay: $delay;

//         // Play the animation for anything between 5-10 seconds
//         animation-duration: $duration;
//         animation-iteration-count: infinite;
//         left: $left;
//         top: -$top;
//         &:after {
//             font-size: $size * 1px;
//         }
//     }
// }

// @keyframes floatingBubble {
//     0% {
//         transform: rotate(0deg);
//         left: $left;
//         top: -$top;
//     }
//     25% {
//         left: $left + 1%;
//     }

//     50% {
//         left: $left;
//     }

//     75% {
//         left: $left + 2%;
//         opacity: 1;
//     }

//     100% {
//         transform: rotate(360deg);
//         top: $top + 40%;
//         opacity: 0;
//     }
// }

@keyframes moveclouds {
  0% {
    opacity: 0;
    bottom: 0;
  }
  1% {
    opacity: 1;
  }
  98% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    bottom: 600px;
  }
}

@keyframes sideWays {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(50px);
  }
}
