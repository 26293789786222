@import '../../styles/utilities/mixins/aspect-ratio.scss';

.stream {
  position: relative;
  overflow: hidden;
  max-width: 1440px;
  width: 100%;
  margin: auto;

  .emoji-plugin-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    &--fullscreen {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
    }
  }

  .player-wrapper {
    @include ratio(16, 9);
    width: 100%;
    position: relative;

    .react-player {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &--fullscreen {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;

      .progress-bar,
      .slide-fullscreen-btn {
        position: fixed;
      }

      & ~ .emotion-bar,
      & ~ .floatingEmojiContainer {
        position: fixed;
      }
    }
  }
}

.error-box {
  padding: 10px;
  text-align: center;
}

.error-box p {
  color: #fff;
}

.debug-box {
  position: absolute;
  left: 40px;
  bottom: 100px;
  background-color: #fff;
  width: 234px;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 20px;
  text-align: center;
}

.webcam-activator {
  background-color: #fff;
  border-radius: 20px;
  position: absolute;
  right: 40px;
  width: 32px;
  height: 32px;
  bottom: 100px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.webcam-activator img {
  width: 75%;
  height: 75%;

  &.closed {
    height: 60%;
    width: 60%;
  }
}

.webcam {
  position: absolute;
  bottom: 180px;
  right: 40px;
  width: 234px;
  height: 178px;
  background-color: #fff;
  opacity: 0;
  transform: translateY(-30px);
  transition: transform 0.35s ease;
}

.webcam-activated {
  opacity: 1;
  transform: translateY(0px);
}

.webcam-video {
  margin: 5px;
}

.muted-btn {
  position: absolute;
  bottom: 85px;
  left: 20px;
}
