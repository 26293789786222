.progress-bar {
  position: absolute;
  bottom: 10px;
  left: 20px;
  right: 20px;
  border-radius: 0.25rem;
  color: #ffffff;
  font-size: 11px;
  cursor: pointer;
  display: flex;
  align-items: center;

  @media (min-width: 992px) {
    bottom: 30px;
    left: 20px;
    right: 460px;
  }

  .rc-slider {
    flex: 1;
    padding: 0;
  }

  .rc-slider,
  .rc-slider-rail,
  .rc-slider-step,
  .rc-slider-track {
    height: 0.5rem;
  }

  .rc-slider-step {
    display: none;
  }

  .rc-slider-track {
    background-image: linear-gradient(to right, #9968ac -2%, #7e61aa 70%, #615ba8 87%);
  }

  .rc-slider-handle {
    display: none;
  }

  .rc-slider-rail {
    background-color: #ffffff;
    opacity: 0.2;
  }

  .play,
  .pause {
    margin-right: 2rem;
  }

  .duration {
    margin-left: 2rem;
  }
}
