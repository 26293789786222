@import '../../styles/utilities//mixins/clean-button.scss';

.icon-button {
  @include clean-button;
  overflow: hidden;
}

.icon-label {
  color: #ffffff;
  font-size: 1rem;
  margin-top: 0.5rem;

  @media (min-width: 992px) {
    visibility: hidden;
    opacity: 0;
    transition: all 200ms ease-in-out;
  }
}

.slide-fullscreen-btn {
  display: none;

  @media (min-width: 992px) {
    display: block;
    position: absolute;
    bottom: 40px;
    right: 405px;
    height: 22px;
    width: 22px;
    fill: #ffffff;
  }
}
