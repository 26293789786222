.sound-slider {
  .rc-slider-vertical {
    height: 100px;
    position: absolute;
    bottom: 140px;
    left: 35px;
  }

  .rc-slider-mark {
    display: none;
  }

  .rc-slider-step {
    display: none;
  }

  .rc-slider-track {
    background-color: #9968ac;
  }

  .rc-slider-rail {
    background-color: #ffffff;
    opacity: 0.2;
  }

  .rc-slider-handle {
    background-image: linear-gradient(to right, #9968ac -2%, #7e61aa 70%, #615ba8 87%);

    border: 0;
  }
}
