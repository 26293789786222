//
// BASE STYLING
// This is where you define your generic resets
// as well as "global" styling for the entire
// site.
// --------------------------------------------------

// https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
html {
  box-sizing: border-box;
  height: 100%;
}

* {
  &,
  &::before,
  &::after {
    box-sizing: inherit;
  }
}

::-moz-selection {
  background: $color__text;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: $color__text;
  color: #fff;
  text-shadow: none;
}

img {
  height: auto;
  max-width: 100%;
}

// Pasted from the verse poc
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  outline: 0;
  button {
    font-family: 'Montserrat', sans-serif;
  }
}
